<template>
  <!--
    The view for the FreshdeskTickets-component.
    Used to display all freshdesk Tickets for user.
  -->
  <Portlet
    title="Support Requests"
    icon="clipboard-check"
  >
    <template slot="buttons">
      <div>
        <span>Last updated at: </span>
        <select
          v-model="yearOrMonth"
          class="form-control mb-1"
        >
          <option
            value="year"
          >
            Last Year
          </option>
          <option
            value="month"
          >
            Last Month
          </option>
        </select>
      </div>
    </template>

    <template v-if="authenticationHasRole('scope_staff')">
      <div class="col-12">
        <toggle-button
          id="viewToggle"
          v-model="filterType"
          :labels="{ checked: 'Company', unchecked: 'Agent' }"
          :color="{ checked: 'gray', unchecked: 'green' }"
          :width="90"
          :height="30"
          :font-size="10"
          class="m-0 mb-3"
        />
      </div>
      
      <LoadingPlaceholder v-if="companiesLoading || agentsLoading" />
      <template v-else>
        <template v-if="filterType">
          <label
            for="roles"
            class="form-label"
          >{{ $t('company') }}</label>
          <Multiselect
            id="companies"
            v-model="selectedCompany"
            tag-placeholder="Not found"
            placeholder="Select a Company"
            :options="companies"
            select-label=""
            :multiple="false"
            :taggable="true"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            :custom-label="customLabel"
            class="mb-4"
          />
        </template>
        <template v-else>
          <label
            for="roles"
            class="form-label"
          >Agent</label>
          <Multiselect
            id="agents"
            v-model="selectedAgent"
            tag-placeholder="Not found"
            placeholder="Select a Agent"
            :options="agents"
            select-label=""
            :multiple="false"
            :taggable="true"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            :custom-label="customLabelAgent"
            class="mb-4"
          />
        </template>

        <FreshdeskUserTicketList
          v-if="selectedCompany || selectedAgent"
          :company-id="selectedCompany ? selectedCompany.id : null"
          :agent-id="selectedAgent ? selectedAgent.id : null"
          :year-or-month="yearOrMonth"
        />
      </template>
    </template>
    <template v-else>
      <FreshdeskUserTicketList :year-or-month="yearOrMonth" />
    </template>
  </Portlet>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "SupportRequestsView",
  components: {
    Multiselect: () => import('vue-multiselect'),
    FreshdeskUserTicketList: () => import('@/components/Freshdesk/FreshdeskUserTicketList.vue')
  },
  mixins: [
    authenticationMixin
  ],
  metaInfo () {
    return {
      title: 'Support Requests'
    }
  },
  data () {
    return {
      companies: null,
      agents: null,
      selectedCompany: null,
      selectedAgent: null,
      companiesLoading: false,
      agentsLoading: false,
      yearOrMonth: 'year',
      filterType: true
    }
  },
  watch: {
    filterType () {
      this.selectedCompany = null;
      this.selectedAgent = null;

    }
  },
  created () {
    if(this.authenticationHasRole('scope_staff')) {
      this.getFreshdeskCompanies();
      this.getFreshdeskAgents();
    }
  },
  methods: {
    customLabel ({ name }) {
      return `${name}`
    },
    customLabelAgent ({ contact }) {
      return `${contact.name}`
    },
    getFreshdeskCompanies () {
      this.companiesLoading = true;
      this.axios.get(`/Freshdesk/GetFreshdeskCompanies`)
      .then((response) => {
        this.companies = response.data;
      })
      .finally(() => { this.companiesLoading = false; });
    },
    getFreshdeskAgents () {
      this.agentsLoading = true;
      this.axios.get(`/Freshdesk/GetFreshdeskAgents`)
      .then((response) => {
        this.agents = response.data;
      })
      .finally(() => { this.agentsLoading = false; });
    },
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>